export const themeStyleDark = {
  backgroundColor: "black",
  primaryColor: "#22CE83",
  primaryCardColor: "#121212",
  textColor: "white",
  primaryButtonColor: "#22CE83",
  primaryButtonTextColor: "black",
  secondaryButtonColor: "",
};

export const themeStyle = {
  backgroundColor: "#ddd",
  primaryColor: "#22CE83",
  primaryCardColor: "white",
  textColor: "black",
  primaryButtonColor: "#22CE83",
  primaryButtonTextColor: "black",
  secondaryButtonColor: "",
};
