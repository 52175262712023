import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import QRCode from "qrcode";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import { Bar } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Chart } from "chart.js/auto";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import QrCodeIcon from "@mui/icons-material/QrCode";
import QrCodeWithLogo from "qrcode-with-logos";
import Slider from "@mui/material/Slider";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Stack from "@mui/material/Stack";
import { API_URL } from "../actions/auth";
import { RgbaStringColorPicker } from "react-colorful";
import SaveIcon from "@mui/icons-material/Save";
import { themeStyle, themeStyleDark } from "../components/Components";
import DeleteIcon from "@mui/icons-material/Delete";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const logo1 = { name: "green1", src: require("../img/1-01.png") };
const logo2 = { name: "green2", src: require("../img/2-01.png") };
const logo3 = { name: "green3", src: require("../img/3-01.png") };
const logo20 = { name: "green4", src: require("../img/green4.png") };
const logo4 = { name: "orange", src: require("../img/4-01.png") };
const logo5 = { name: "yellow", src: require("../img/5-01.png") };
const logo6 = { name: "blue", src: require("../img/6-01.png") };
const logo7 = { name: "skyblue", src: require("../img/7-01.png") };
const logo8 = { name: "amber", src: require("../img/8-01.png") };
const logo9 = { name: "red", src: require("../img/9-01.png") };
const logo10 = { name: "vermilion", src: require("../img/10-01.png") };
const logo11 = { name: "purple", src: require("../img/11-01.png") };
const logo12 = { name: "grey", src: require("../img/12-01.png") };
const logo13 = { name: "lightyellow", src: require("../img/13-01.png") };
const logo14 = { name: "pink", src: require("../img/14-01.png") };
const logo15 = { name: "gold", src: require("../img/15-01.png") };
const logo16 = { name: "silver", src: require("../img/16-01.png") };
const logo17 = { name: "lightblue", src: require("../img/17-01.png") };
const logo18 = { name: "black", src: require("../img/18-01.png") };
const logo19 = { name: "white", src: require("../img/19-01.png") };
const white = { name: "white2", src: require("../img/white.png") };
const original = { name: "original", src: require("../img/original.png") };

function Profiles() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [ogUsers, setOGusers] = useState([]);
  const [random, setRandom] = useState(0);
  const [search, setSearch] = useState("");
  const [selectedPage, setSelectedPage] = useState("PROFILES");
  const [currentPage, setCurrentPage] = useState(1);

  const [expandedBackground, setExpandedBackground] = useState(false);
  const [expandedForeground, setExpandedForeground] = useState(false);
  const [expandedLogoBackground, setExpandedLogoBackground] = useState(false);
  const [expandedDotColor, setExpandedDotColor] = useState(false);
  const [expandedLogoOptions, setExpandedLogoOptions] = useState(false);
  const [expandedDesignOptions, setExpandedDesignOptions] = useState(false);
  const [expandedBulk, setExpandedBulk] = useState(false);
  const [bulkCount, setBulkCount] = useState("100");
  const [fromDesign, setFromDesign] = useState(false);

  const [dark, setDark] = useState("#000000");
  const [light, setLight] = useState("#FFFFFF");
  const [margin, setMargin] = useState(5);

  const [logoRadius, setLogoRadius] = useState(5);
  const [borderWidth, setBorderWidth] = useState(5);
  const [borderRadius, setBorderRadius] = useState(5);
  const [bgColor, setBgColor] = useState("#000000");
  const [src, setSrc] = useState(5);

  const [dotType, setDotType] = useState("dot");
  const [dotColor, setDotColor] = useState("#000000");

  const [cornerType, setCornerType] = useState("square");
  const [radiusInner, setRadiusInner] = useState(5);
  const [radiusOuter, setRadiusOuter] = useState(5);
  const [content, setContent] = useState(
    "https://profile.techcards.in/testu_wzjjg/uewmsz"
  );
  const [qrDesigns, setQRDesigns] = useState([]);
  const [qrname, setQRName] = useState("");
  const [selectedQR, setSelectedQR] = useState({});
  const [logo, setLogo] = useState(logo1);
  const [downloadCount, setDownloadCount] = useState(2);
  const [linkList, setLinkList] = useState([]);

  let qrcode = new QrCodeWithLogo({
    canvas: document.getElementById("canvas"),
    content: content,
    width: 800,
    nodeQrCodeOptions: {
      margin: margin,
      color: {
        dark: dark,
        light: light,
      },
    },
    logo: {
      logoRadius: logoRadius,
      borderWidth: borderWidth,
      borderRadius: borderRadius,
      bgColor: bgColor,
      src: logo["src"],
    },
    dotsOptions: {
      type: dotType,
      color: dotColor,
    },
    cornersOptions: {
      type: cornerType,
      radius: {
        inner: radiusInner,
        outer: radiusOuter,
      },
    },
  });

  async function downLoadQRCodesBulk() {
    if (parseInt(bulkCount) <= 0) {
      createAlert("ERROR", "Error!", "Download count should be at least 1");
    } else {
      var body = {};
      console.log(state.auth.user.uid);
      const config = {
        headers: {
          Authorization: state.auth.token,
          "X-CSRFToken": state.auth.csrfToken,
          "Content-Type": "application/json",
        },
      };

      body["uid"] = state.auth.user.uid;
      body["count"] = parseInt(bulkCount);

      axios
        .post(API_URL + "get_bulk_qr", body, config)
        .then(async (res) => {
          setIsLoading(true);
          setLinkList(res.data);
          var count = 0;
          var intervalId = window.setInterval(() => {
            if (count < bulkCount) {
              var qrname =
                res.data[count].split("//")[1].split("/")[1] +
                "_" +
                res.data[count].split("//")[1].split("/")[2];
              new QrCodeWithLogo({
                image: document.getElementById("image"),
                content: res.data[count],
                width: 800,
                nodeQrCodeOptions: {
                  margin: margin,
                  color: {
                    dark: dark,
                    light: light,
                  },
                },
                logo: {
                  logoRadius: logoRadius,
                  borderWidth: borderWidth,
                  borderRadius: borderRadius,
                  bgColor: bgColor,
                  src: logo["src"],
                },
                dotsOptions: {
                  type: dotType,
                  color: dotColor,
                },
                cornersOptions: {
                  type: cornerType,
                  radius: {
                    inner: radiusInner,
                    outer: radiusOuter,
                  },
                },
                downloadName: qrname + ".png",
                download(downloadFn) {
                  downloadFn();
                },
              });
              count++;
              console.log(count);
            } else {
              clearInterval(intervalId);
            }
          }, 500);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }

  let qrcode2 = new QrCodeWithLogo({
    image: document.getElementById("image"),
    content: content,
    width: 800,
    nodeQrCodeOptions: {
      margin: margin,
      color: {
        dark: dark,
        light: light,
      },
    },
    logo: {
      logoRadius: logoRadius,
      borderWidth: borderWidth,
      borderRadius: borderRadius,
      bgColor: bgColor,
      src: logo["src"],
    },
    dotsOptions: {
      type: dotType,
      color: dotColor,
    },
    cornersOptions: {
      type: cornerType,
      radius: {
        inner: radiusInner,
        outer: radiusOuter,
      },
    },
  });

  function addNewQR() {
    var body = {
      email: state.auth.user.email,
      qr: {
        id: makeid(5),
        name: qrname,
        nodeQrCodeOptions: {
          margin: margin,
          color: {
            dark: dark,
            light: light,
          },
        },
        logo: {
          logoRadius: logoRadius,
          borderWidth: borderWidth,
          borderRadius: borderRadius,
          bgColor: bgColor,
        },
        dotsOptions: {
          type: dotType,
          color: dotColor,
        },
        cornersOptions: {
          type: cornerType,
          radius: {
            inner: radiusInner,
            outer: radiusOuter,
          },
        },
      },
    };
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    axios.post(API_URL + "add_new_qr_design", body, config).then((res) => {
      setQRDesigns(res.data);
      setSelectedQR(res.data[0]);

      setQRName(res.data[0]["name"]);

      setMargin(res.data[0]["nodeQrCodeOptions"]["margin"]);
      setDark(res.data[0]["nodeQrCodeOptions"]["color"]["dark"]);
      setLight(res.data[0]["nodeQrCodeOptions"]["color"]["light"]);

      setLogoRadius(res.data[0]["logo"]["logoRadius"]);
      setBorderWidth(res.data[0]["logo"]["borderWidth"]);
      setBorderRadius(res.data[0]["logo"]["borderRadius"]);
      setBgColor(res.data[0]["logo"]["bgColor"]);

      setDotType(res.data[0]["dotsOptions"]["type"]);
      setDotColor(res.data[0]["dotsOptions"]["color"]);

      setCornerType(res.data[0]["cornersOptions"]["type"]);
      setRadiusInner(res.data[0]["cornersOptions"]["radius"]["inner"]);
      setRadiusOuter(res.data[0]["cornersOptions"]["radius"]["outer"]);

      setIsLoading(false);
      setIsLoading(false);
    });
  }

  function handleQrChange(val) {
    setIsLoading(true);
    console.log(val);
    setSelectedQR(val);

    setQRName(val["name"]);

    setMargin(val["nodeQrCodeOptions"]["margin"]);
    setDark(val["nodeQrCodeOptions"]["color"]["dark"]);
    setLight(val["nodeQrCodeOptions"]["color"]["light"]);

    setLogoRadius(val["logo"]["logoRadius"]);
    setBorderWidth(val["logo"]["borderWidth"]);
    setBorderRadius(val["logo"]["borderRadius"]);
    setBgColor(val["logo"]["bgColor"]);

    setDotType(val["dotsOptions"]["type"]);
    setDotColor(val["dotsOptions"]["color"]);

    setCornerType(val["cornersOptions"]["type"]);
    setRadiusInner(val["cornersOptions"]["radius"]["inner"]);
    setRadiusOuter(val["cornersOptions"]["radius"]["outer"]);

    setIsLoading(false);
  }

  function deleteQR() {
    var body = {
      email: state.auth.user.email,
      qr_id: selectedQR["id"],
    };
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    axios.post(API_URL + "delete_qr_design", body, config).then((res) => {
      setQRDesigns(res.data);
      setSelectedQR(res.data[0]);

      setQRName(res.data[0]["name"]);

      setMargin(res.data[0]["nodeQrCodeOptions"]["margin"]);
      setDark(res.data[0]["nodeQrCodeOptions"]["color"]["dark"]);
      setLight(res.data[0]["nodeQrCodeOptions"]["color"]["light"]);

      setLogoRadius(res.data[0]["logo"]["logoRadius"]);
      setBorderWidth(res.data[0]["logo"]["borderWidth"]);
      setBorderRadius(res.data[0]["logo"]["borderRadius"]);
      setBgColor(res.data[0]["logo"]["bgColor"]);

      setDotType(res.data[0]["dotsOptions"]["type"]);
      setDotColor(res.data[0]["dotsOptions"]["color"]);

      setCornerType(res.data[0]["cornersOptions"]["type"]);
      setRadiusInner(res.data[0]["cornersOptions"]["radius"]["inner"]);
      setRadiusOuter(res.data[0]["cornersOptions"]["radius"]["outer"]);

      setIsLoading(false);
      setIsLoading(false);
    });
  }

  function handleSearch(text) {
    setSearch(text);
    if (text.length == 0) {
      setUsers(ogUsers);
      setSearch("");
      setRandom(makeid(5));
    } else {
      var tempUsers = [];

      for (var i = 0; i < ogUsers.length; i++) {
        if (
          ogUsers[i]["full_name"].includes(text) ||
          ogUsers[i]["email"].includes(text)
        ) {
          tempUsers.push(ogUsers[i]);
        }
      }
      setCurrentPage(1);
      setUsers(tempUsers);
      setRandom(makeid(5));
    }
  }

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function qrcodegen(url, index) {
    QRCode.toDataURL(
      url,
      {
        width: 2000,
        margin: 5,
        color: { dark: "#000000FF", light: "#EEEEEEFF" },
      },
      (err, qc) => {
        if (err) return console.error(err);

        var tempUsers = users;
        tempUsers[index]["qr"] = qc;
        setUsers(tempUsers);
        setRandom(makeid(5));
      }
    );
  }

  function handleCurrentPage(val) {
    var tempUsers = Array.from(ogUsers);
    setSearch("");
    setCurrentPage(val);
    setUsers(tempUsers.splice((val - 1) * 10, 10));
  }

  function getData() {
    var body = {
      email: state.auth.user.email,
    };
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    axios.post(API_URL + "get_users", body, config).then((res) => {
      setUsers(res.data.splice(0, 10));
      setOGusers(res.data);
      axios.get(API_URL + "get_qrdesigns", config).then((res) => {
        setQRDesigns(res.data);
        setSelectedQR(res.data[0]);

        setQRName(res.data[0]["name"]);

        setMargin(res.data[0]["nodeQrCodeOptions"]["margin"]);
        setDark(res.data[0]["nodeQrCodeOptions"]["color"]["dark"]);
        setLight(res.data[0]["nodeQrCodeOptions"]["color"]["light"]);

        setLogoRadius(res.data[0]["logo"]["logoRadius"]);
        setBorderWidth(res.data[0]["logo"]["borderWidth"]);
        setBorderRadius(res.data[0]["logo"]["borderRadius"]);
        setBgColor(res.data[0]["logo"]["bgColor"]);

        setDotType(res.data[0]["dotsOptions"]["type"]);
        setDotColor(res.data[0]["dotsOptions"]["color"]);

        setCornerType(res.data[0]["cornersOptions"]["type"]);
        setRadiusInner(res.data[0]["cornersOptions"]["radius"]["radiusInner"]);
        setRadiusOuter(res.data[0]["cornersOptions"]["radius"]["radiusOuter"]);

        setIsLoading(false);
      });
    });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
  }

  function setHasBought(index, checked) {
    var tempUsers = users;
    tempUsers[index]["has_bought"] = checked;
    setUsers(tempUsers);
    setRandom(makeid(5));

    var body = {
      uid: tempUsers[index]["uid"],
      bought: checked,
    };
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    axios.post(API_URL + "update_bought", body, config).then((res) => {});
  }

  function getQrDesigns() {
    var a = qrDesigns.map((qrdesign) => {
      return <MenuItem value={qrdesign}>{qrdesign["name"]}</MenuItem>;
    });

    return a;
  }

  console.log(dotType);
  console.log(cornerType);
  function getContent() {
    if (selectedPage == "PROFILES") {
      return (
        <Fragment>
          <TextField
            fullWidth
            id="outlined-basic"
            placeholder="Search User / Email"
            variant="outlined"
            value={search}
            size="small"
            sx={{
              input: {
                fontSize: "24px",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
              },
            }}
            style={{
              height: "55px",
              border:
                state.auth.theme == "dark"
                  ? "2px solid " + themeStyleDark.primaryColor
                  : "2px solid " + themeStyle.primaryColor,
              backgroundColor:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryCardColor
                  : themeStyle.primaryCardColor,

              marginBottom: "30px",
            }}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginBottom: "20px",
            }}
          >
            {Array.from(Array(Math.ceil(ogUsers.length / 10)).keys()).map(
              (temp, index) => (
                <div
                  style={{
                    cursor: "pointer",
                    margin: "3px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "25px",
                    height: "25px",
                    borderRadius: "30px",
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                  }}
                  onClick={() => {
                    handleCurrentPage(index + 1);
                  }}
                >
                  {index + 1}
                </div>
              )
            )}
          </div>
          <div
            style={{
              backgroundColor:
                state.auth.theme == "dark"
                  ? themeStyleDark.primaryCardColor
                  : themeStyle.primaryCardColor,
              borderRadius: "15px",
              padding: "15px",
            }}
          >
            <Grid
              container
              spacing={2}
              style={{
                justifyContent: "flex-start",
                textAlign: "left",
                color:
                  state.auth.theme == "dark"
                    ? themeStyleDark.primaryColor
                    : themeStyle.primaryColor,
                paddingBottom: "20px",
                borderBottom:
                  state.auth.theme == "dark"
                    ? "1px solid" + themeStyleDark.primaryColor
                    : "1px solid" + themeStyle.primaryColor,
              }}
            >
              <Grid item md={1}>
                No.
              </Grid>
              <Grid item md={3}>
                Full Name
              </Grid>
              <Grid item md={3}>
                Email
              </Grid>
              <Grid item md={1}>
                Date
              </Grid>
              <Grid item md={1}>
                Profiles
              </Grid>
              <Grid item md={1}>
                Contacts
              </Grid>
              <Grid item md={1}>
                Has Card
              </Grid>
              <Grid item md={1}>
                Download QR
              </Grid>
            </Grid>
            {users.map((user, index) => (
              <Grid
                container
                spacing={2}
                style={{
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.textColor
                      : themeStyle.textColor,
                  padding: "15px",
                }}
              >
                <Grid item md={1}>
                  {(currentPage - 1) * 10 + (index + 1)}
                </Grid>
                <Grid
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    navigate("/contacts", {
                      replace: false,
                      state: { uid: user.uid, name: user["full_name"] },
                    })
                  }
                  item
                  md={3}
                >
                  {user["full_name"]}
                </Grid>
                <Grid item md={3}>
                  {user["email"]}
                </Grid>
                <Grid item md={1}>
                  {user["date"].replace("00:00", "")}
                </Grid>
                <Grid item md={1} style={{ textAlign: "center" }}>
                  {user["profiles"]}
                </Grid>
                <Grid item md={1} style={{ textAlign: "center" }}>
                  {user["contacts"]}
                </Grid>
                <Grid item md={1} style={{ textAlign: "center" }}>
                  <div
                    style={{
                      display: "inline-flex",
                      marginLeft: "-15px",
                    }}
                  >
                    <Checkbox
                      checked={user["has_bought"]}
                      labelStyle={{
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                      }}
                      iconStyle={{
                        fill:
                          state.auth.theme == "light"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                      }}
                      onChange={(e) => setHasBought(index, e.target.checked)}
                      value={1}
                      color={"primary"}
                      style={{
                        marginTop: "-6px",
                        marginLeft: "3px",
                        transform: "scale(1.25)",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                      }}
                    />
                    <span
                      style={{
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        marginTop: "-2px",
                        marginLeft: "10px",
                        fontSize: "20px",
                      }}
                    >
                      {user["has_bought"] ? "PAID" : "FREE"}
                    </span>
                  </div>
                </Grid>
                <Grid
                  item
                  md={1}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {user["qr"].length == 0 ? (
                    <Button
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedPage("QR DESIGN");
                        setExpandedDesignOptions(true);
                        setFromDesign(false);
                        setContent(
                          "https://profile.techcards.in/" +
                            user["profile_link"] +
                            "/" +
                            user["uid"]
                        );
                      }}
                    >
                      <QrCodeIcon
                        style={{
                          fontSize: "30px",
                          marginTop: "-5px",
                          color:
                            state.auth.theme == "dark"
                              ? themeStyleDark.textColor
                              : themeStyle.textColor,
                          textAlign: "center",
                        }}
                      />
                    </Button>
                  ) : (
                    <Button
                      style={{ cursor: "pointer" }}
                      href={user["qr"]}
                      download={user["profile_link"] + ".png"}
                    >
                      <DownloadIcon
                        style={{
                          marginTop: "-5px",
                          fontSize: "30px",
                          color:
                            state.auth.theme == "dark"
                              ? themeStyleDark.textColor
                              : themeStyle.textColor,
                          textAlign: "center",
                        }}
                      />
                    </Button>
                  )}
                </Grid>
              </Grid>
            ))}
          </div>
        </Fragment>
      );
    } else if (selectedPage == "QR DESIGN") {
      return (
        <Fragment>
          <Grid
            container
            spacing={10}
            style={{
              color:
                state.auth.theme == "dark"
                  ? themeStyleDark.textColor
                  : themeStyle.textColor,
              padding: "15px",
            }}
          >
            <Grid
              item
              md={6}
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <canvas id="canvas" style={{ width: "800px", height: "800px" }} />

              <img
                id="image"
                style={{ width: "1000px", height: "1000px", display: "none" }}
              />
              <br />
            </Grid>
            <Grid item md={6}>
              <Stack
                spacing={2}
                direction="row"
                sx={{ alignItems: "center", mb: 1 }}
              >
                <div
                  style={{
                    width: "500px",
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.textColor
                        : themeStyle.textColor,
                    fontSize: "25px",
                  }}
                >
                  Margin
                </div>
                <RemoveCircleOutlineIcon
                  style={{
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                  }}
                />
                <Slider
                  aria-label="Volume"
                  value={margin}
                  onChange={(e) => {
                    setMargin(e.target.value);
                  }}
                />
                <AddCircleOutlineIcon
                  style={{
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                  }}
                />
              </Stack>
              <br />
              <Accordion
                sx={{
                  backgroundColor:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryCardColor
                      : themeStyle.primaryCardColor,
                  borderRadius: 2.5,
                }}
                expanded={expandedBackground}
                onChange={(e) => {
                  setExpandedBackground(!expandedBackground);
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                      }}
                    />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div
                    style={{
                      width: "500px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                      fontSize: "25px",
                    }}
                  >
                    Background <span style={{ color: light }}>{light}</span>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack
                    spacing={10}
                    direction="row"
                    sx={{ alignItems: "center", mb: 1 }}
                  >
                    <TextField
                      sx={{
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        borderRadius: "6px",
                      }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="phonenumber"
                      autoComplete="phonenumber"
                      autoFocus
                      placeholder="Background Color"
                      value={light}
                      onChange={(e) => setLight(e.target.value)}
                    />
                    <RgbaStringColorPicker value={light} onChange={setLight} />
                  </Stack>{" "}
                </AccordionDetails>
              </Accordion>
              <br />
              <Accordion
                sx={{
                  backgroundColor:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryCardColor
                      : themeStyle.primaryCardColor,
                  borderRadius: 2.5,
                }}
                expanded={expandedForeground}
                onChange={(e) => {
                  setExpandedForeground(!expandedForeground);
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                      }}
                    />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div
                    style={{
                      width: "500px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                      fontSize: "25px",
                    }}
                  >
                    Foreground <span style={{ color: dark }}>{dark}</span>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack
                    spacing={10}
                    direction="row"
                    sx={{ alignItems: "center", mb: 1 }}
                  >
                    <TextField
                      sx={{
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        borderRadius: "6px",
                      }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="phonenumber"
                      autoComplete="phonenumber"
                      autoFocus
                      placeholder="Foreground Color"
                      value={dark}
                      onChange={(e) => setDark(e.target.value)}
                    />
                    <RgbaStringColorPicker value={dark} onChange={setDark} />
                  </Stack>{" "}
                </AccordionDetails>
              </Accordion>
              <br />
              <Accordion
                sx={{
                  backgroundColor:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryCardColor
                      : themeStyle.primaryCardColor,
                  borderRadius: 2.5,
                }}
                expanded={expandedLogoBackground}
                onChange={(e) => {
                  setExpandedLogoBackground(!expandedLogoBackground);
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                      }}
                    />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div
                    style={{
                      width: "500px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                      fontSize: "25px",
                    }}
                  >
                    Logo Background{" "}
                    <span style={{ color: bgColor }}>{bgColor}</span>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack
                    spacing={10}
                    direction="row"
                    sx={{ alignItems: "center", mb: 1 }}
                  >
                    <TextField
                      sx={{
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        borderRadius: "6px",
                      }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="phonenumber"
                      autoComplete="phonenumber"
                      autoFocus
                      placeholder="Logo Background Color"
                      value={bgColor}
                      onChange={(e) => setBgColor(e.target.value)}
                    />
                    <RgbaStringColorPicker
                      value={bgColor}
                      onChange={setBgColor}
                    />
                  </Stack>{" "}
                </AccordionDetails>
              </Accordion>
              <br />
              <Accordion
                sx={{
                  backgroundColor:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryCardColor
                      : themeStyle.primaryCardColor,
                  borderRadius: 2.5,
                }}
                expanded={expandedDotColor}
                onChange={(e) => {
                  setExpandedDotColor(!expandedDotColor);
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                      }}
                    />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div
                    style={{
                      width: "500px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                      fontSize: "25px",
                    }}
                  >
                    Dot Color{" "}
                    <span style={{ color: dotColor }}>{dotColor}</span>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack
                    spacing={10}
                    direction="row"
                    sx={{ alignItems: "center", mb: 1 }}
                  >
                    <TextField
                      sx={{
                        backgroundColor:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        borderRadius: "6px",
                      }}
                      variant="outlined"
                      required
                      fullWidth
                      margin="normal"
                      name="phonenumber"
                      autoComplete="phonenumber"
                      autoFocus
                      placeholder="Logo Background Color"
                      value={dotColor}
                      onChange={(e) => setDotColor(e.target.value)}
                    />
                    <RgbaStringColorPicker
                      value={dotColor}
                      onChange={setDotColor}
                    />
                  </Stack>
                </AccordionDetails>
              </Accordion>
              <br />
              <Stack
                spacing={2}
                direction="row"
                sx={{ alignItems: "center", mb: 1 }}
              >
                <div
                  style={{
                    width: "500px",
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.textColor
                        : themeStyle.textColor,
                    fontSize: "25px",
                  }}
                >
                  Dot Type
                </div>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={dotType}
                  label="Dot type"
                  sx={{
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                    fontWeight: "bold",
                    width: "100%",
                  }}
                  onChange={(e) => setDotType(e.target.value)}
                >
                  <MenuItem value={"dot"}>dot</MenuItem>
                  <MenuItem value={"dot-small"}>dot-small</MenuItem>
                  <MenuItem value={"rounded"}>rounded</MenuItem>
                  <MenuItem value={"tile"}>tile</MenuItem>
                  <MenuItem value={"square"}>square</MenuItem>
                  <MenuItem value={"star"}>star</MenuItem>
                  <MenuItem value={"diamond"}>diamond</MenuItem>
                  <MenuItem value={"fluid-line"}>fluid-line</MenuItem>
                  <MenuItem value={"fluid"}>fluid</MenuItem>
                  <MenuItem value={"stripe"}>stripe</MenuItem>
                  <MenuItem value={"stripe-row"}>stripe-row</MenuItem>
                  <MenuItem value={"tile"}>stripe-column</MenuItem>
                </Select>
              </Stack>
              <br />
              <Stack
                spacing={2}
                direction="row"
                sx={{ alignItems: "center", mb: 1 }}
              >
                <div
                  style={{
                    width: "500px",
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.textColor
                        : themeStyle.textColor,
                    fontSize: "25px",
                  }}
                >
                  Corner Type
                </div>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={cornerType}
                  label="Corner type"
                  sx={{
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                    fontWeight: "bold",
                    width: "100%",
                  }}
                  onChange={(e) => setCornerType(e.target.value)}
                >
                  <MenuItem value={"square"}>square</MenuItem>
                  <MenuItem value={"rounded"}>rounded</MenuItem>
                  <MenuItem value={"rounded-circle"}>rounded-circle</MenuItem>
                  <MenuItem value={"circle-rounded"}>circle-rounded</MenuItem>
                  <MenuItem value={"circle-diamond"}>circle-diamond</MenuItem>
                  <MenuItem value={"circle-star"}>circle-star</MenuItem>
                </Select>
              </Stack>
              <br />
              <Accordion
                sx={{
                  backgroundColor:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryCardColor
                      : themeStyle.primaryCardColor,
                  borderRadius: 2.5,
                }}
                expanded={expandedLogoOptions}
                onChange={(e) => {
                  setExpandedLogoOptions(!expandedLogoOptions);
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                      }}
                    />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div
                    style={{
                      width: "500px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                      fontSize: "25px",
                    }}
                  >
                    Logo Options
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack
                    spacing={2}
                    direction="row"
                    sx={{ alignItems: "center", mb: 1 }}
                  >
                    <div
                      style={{
                        width: "500px",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        fontSize: "25px",
                      }}
                    >
                      Inner Radius
                    </div>
                    <RemoveCircleOutlineIcon
                      style={{
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                      }}
                    />
                    <Slider
                      aria-label="Volume"
                      value={radiusInner}
                      onChange={(e) => {
                        setRadiusInner(e.target.value);
                      }}
                    />
                    <AddCircleOutlineIcon
                      style={{
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                      }}
                    />
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    sx={{ alignItems: "center", mb: 1 }}
                  >
                    <div
                      style={{
                        width: "500px",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        fontSize: "25px",
                      }}
                    >
                      Outer Radius
                    </div>
                    <RemoveCircleOutlineIcon
                      style={{
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                      }}
                    />
                    <Slider
                      aria-label="Volume"
                      value={radiusOuter}
                      onChange={(e) => {
                        setRadiusOuter(e.target.value);
                      }}
                    />
                    <AddCircleOutlineIcon
                      style={{
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                      }}
                    />
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    sx={{ alignItems: "center", mb: 1 }}
                  >
                    <div
                      style={{
                        width: "500px",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        fontSize: "25px",
                      }}
                    >
                      Logo Border Width
                    </div>
                    <RemoveCircleOutlineIcon
                      style={{
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                      }}
                    />
                    <Slider
                      aria-label="Volume"
                      value={borderWidth}
                      onChange={(e) => {
                        setBorderWidth(e.target.value);
                      }}
                    />
                    <AddCircleOutlineIcon
                      style={{
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                      }}
                    />
                  </Stack>
                  <Stack
                    spacing={2}
                    direction="row"
                    sx={{ alignItems: "center", mb: 1 }}
                  >
                    <div
                      style={{
                        width: "500px",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        fontSize: "25px",
                      }}
                    >
                      Logo Border Radius
                    </div>
                    <RemoveCircleOutlineIcon
                      style={{
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                      }}
                    />
                    <Slider
                      aria-label="Volume"
                      value={logoRadius}
                      onChange={(e) => {
                        setLogoRadius(e.target.value);
                      }}
                    />
                    <AddCircleOutlineIcon
                      style={{
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                      }}
                    />
                  </Stack>
                </AccordionDetails>
              </Accordion>
              <Accordion
                sx={{
                  backgroundColor:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryCardColor
                      : themeStyle.primaryCardColor,
                  borderRadius: 2.5,
                }}
                expanded={expandedDesignOptions}
                onChange={(e) => {
                  setExpandedDesignOptions(!expandedDesignOptions);
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.primaryColor
                            : themeStyle.primaryColor,
                      }}
                    />
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <div
                    style={{
                      width: "500px",
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                      fontSize: "25px",
                    }}
                  >
                    Design Options
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                      fontSize: "25px",
                    }}
                  >
                    Design Name
                  </div>
                  <TextField
                    sx={{
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                      borderRadius: "6px",
                    }}
                    variant="outlined"
                    required
                    fullWidth
                    margin="normal"
                    name="phonenumber"
                    autoComplete="phonenumber"
                    autoFocus
                    placeholder="QR Design Name"
                    value={qrname}
                    onChange={(e) => setQRName(e.target.value)}
                  />
                  <div
                    style={{
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                      fontSize: "25px",
                    }}
                  >
                    QR Link
                  </div>
                  <TextField
                    sx={{
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                      borderRadius: "6px",
                    }}
                    variant="outlined"
                    required
                    fullWidth
                    margin="normal"
                    name="phonenumber"
                    autoComplete="phonenumber"
                    autoFocus
                    placeholder="QR Design Name"
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                  />

                  <div
                    style={{
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                      fontSize: "25px",
                    }}
                  >
                    Select design
                  </div>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedQR}
                    label="QR Design"
                    sx={{
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontWeight: "bold",
                      width: "100%",
                    }}
                    onChange={(e) => handleQrChange(e.target.value)}
                  >
                    {getQrDesigns()}
                  </Select>
                  <br />
                  <br />

                  <div
                    style={{
                      color:
                        state.auth.theme == "dark"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                      fontSize: "25px",
                    }}
                  >
                    Select Logo
                  </div>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={logo}
                    label="Logo"
                    sx={{
                      backgroundColor:
                        state.auth.theme == "dark"
                          ? themeStyleDark.primaryColor
                          : themeStyle.primaryColor,
                      fontWeight: "bold",
                      width: "100%",
                    }}
                    onChange={(e) => setLogo(e.target.value)}
                  >
                    <MenuItem value={logo1}>{logo1["name"]}</MenuItem>
                    <MenuItem value={logo2}>{logo2["name"]}</MenuItem>
                    <MenuItem value={logo3}>{logo3["name"]}</MenuItem>
                    <MenuItem value={logo20}>{logo20["name"]}</MenuItem>
                    <MenuItem value={logo4}>{logo4["name"]}</MenuItem>
                    <MenuItem value={logo5}>{logo5["name"]}</MenuItem>
                    <MenuItem value={logo6}>{logo6["name"]}</MenuItem>
                    <MenuItem value={logo7}>{logo7["name"]}</MenuItem>
                    <MenuItem value={logo8}>{logo8["name"]}</MenuItem>
                    <MenuItem value={logo9}>{logo9["name"]}</MenuItem>
                    <MenuItem value={logo10}>{logo10["name"]}</MenuItem>
                    <MenuItem value={logo11}>{logo11["name"]}</MenuItem>
                    <MenuItem value={logo12}>{logo12["name"]}</MenuItem>
                    <MenuItem value={logo13}>{logo13["name"]}</MenuItem>
                    <MenuItem value={logo14}>{logo14["name"]}</MenuItem>
                    <MenuItem value={logo15}>{logo15["name"]}</MenuItem>
                    <MenuItem value={logo16}>{logo16["name"]}</MenuItem>
                    <MenuItem value={logo17}>{logo17["name"]}</MenuItem>
                    <MenuItem value={logo18}>{logo18["name"]}</MenuItem>
                    <MenuItem value={logo19}>{logo19["name"]}</MenuItem>
                    <MenuItem value={white}>{white["name"]}</MenuItem>
                    <MenuItem value={original}>{original["name"]}</MenuItem>
                  </Select>
                </AccordionDetails>
              </Accordion>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  style={{
                    fontSize: "18px",
                    marginTop: "25px",
                    height: "45px",
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                  }}
                  onClick={() => {
                    addNewQR();
                  }}
                  variant="contained"
                  color="primary"
                >
                  <span
                    style={{
                      color:
                        state.auth.theme == "light"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                    }}
                  >
                    SAVE QR Design
                  </span>
                  <SaveIcon
                    style={{
                      fontSize: "30px",
                      color:
                        state.auth.theme == "light"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                      marginLeft: "15px",
                    }}
                  />
                </Button>
                <Button
                  style={{
                    marginLeft: "15px",
                    fontSize: "18px",
                    marginTop: "25px",
                    height: "45px",
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                  }}
                  onClick={() => {
                    qrcode2.downloadImage(
                      content.split("//")[1].split("/")[1] +
                        "_" +
                        content.split("//")[1].split("/")[2]
                    );
                  }}
                  variant="contained"
                  color="primary"
                >
                  <span
                    style={{
                      color:
                        state.auth.theme == "light"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                    }}
                  >
                    Download QR
                  </span>
                  <QrCodeIcon
                    style={{
                      fontSize: "30px",
                      color:
                        state.auth.theme == "light"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                      marginLeft: "15px",
                    }}
                  />
                </Button>
                <Button
                  style={{
                    marginLeft: "15px",
                    fontSize: "18px",
                    marginTop: "25px",
                    height: "45px",
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                  }}
                  onClick={() => deleteQR()}
                  variant="contained"
                  color="primary"
                >
                  <span
                    style={{
                      color:
                        state.auth.theme == "light"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                    }}
                  >
                    DELETE QR
                  </span>
                  <DeleteIcon
                    style={{
                      fontSize: "30px",
                      color:
                        state.auth.theme == "light"
                          ? themeStyleDark.textColor
                          : themeStyle.textColor,
                      marginLeft: "15px",
                    }}
                  />
                </Button>
              </div>
              <br />
              <br />

              <br />
              {fromDesign ? (
                <Accordion
                  sx={{
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryCardColor
                        : themeStyle.primaryCardColor,
                    borderRadius: 2.5,
                  }}
                  expanded={expandedBulk}
                  onChange={(e) => {
                    setExpandedBulk(!expandedBulk);
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon
                        sx={{
                          color:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                        }}
                      />
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <div
                      style={{
                        width: "500px",
                        color:
                          state.auth.theme == "dark"
                            ? themeStyleDark.textColor
                            : themeStyle.textColor,
                        fontSize: "25px",
                      }}
                    >
                      Bulk Download
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Stack
                      spacing={10}
                      direction="row"
                      sx={{ alignItems: "center", mb: 1 }}
                    >
                      <TextField
                        sx={{
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                          color:
                            state.auth.theme == "dark"
                              ? themeStyleDark.textColor
                              : themeStyle.textColor,
                          borderRadius: "6px",
                        }}
                        variant="outlined"
                        required
                        fullWidth
                        margin="normal"
                        name="phonenumber"
                        autoComplete="phonenumber"
                        autoFocus
                        placeholder="Background Color"
                        value={bulkCount}
                        onChange={(e) => setBulkCount(e.target.value)}
                      />
                      <Button
                        style={{
                          width: "250px",
                          marginLeft: "15px",
                          fontSize: "18px",
                          height: "45px",
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                        }}
                        onClick={() => downLoadQRCodesBulk()}
                        variant="contained"
                        color="primary"
                      >
                        <span
                          style={{
                            color:
                              state.auth.theme == "light"
                                ? themeStyleDark.textColor
                                : themeStyle.textColor,
                          }}
                        >
                          BULK QR
                        </span>
                        <DownloadIcon
                          style={{
                            fontSize: "30px",
                            color:
                              state.auth.theme == "light"
                                ? themeStyleDark.textColor
                                : themeStyle.textColor,
                            marginLeft: "15px",
                          }}
                        />
                      </Button>
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </Fragment>
      );
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (isLoading) {
        return (
          <Fragment>
            <Sidebar />
            <div
              style={{
                maxWidth: "88vw",
                marginTop: "45vh",
                marginLeft: "8vw",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Sidebar
              tabs={
                <Grid container spacing={1} justifyContent={"flex-end"}>
                  <Grid item md={4}>
                    {selectedPage == "PROFILES" ? (
                      <Button
                        fullWidth
                        value="PROFILES"
                        onClick={() => setSelectedPage("PROFILES")}
                        p={2}
                        style={{
                          marginTop: "-15px",
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                          color:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryButtonTextColor
                              : themeStyle.primaryButtonTextColor,
                          height: "25px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                            letterSpacing: "1.5px",
                          }}
                        >
                          PROFILES
                        </h3>
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        value="PROFILES"
                        onClick={() => setSelectedPage("PROFILES")}
                        p={2}
                        style={{
                          height: "25px",
                          marginTop: "-15px",
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryCardColor
                              : themeStyle.primaryCardColor,
                          color:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryButtonTextColor
                              : themeStyle.primaryButtonTextColor,
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                            color: "#084594",
                          }}
                        >
                          PROFILES
                        </h3>
                      </Button>
                    )}
                  </Grid>
                  <Grid item md={4}>
                    {selectedPage == "QR DESIGN" ? (
                      <Button
                        fullWidth
                        value="QR DESIGN"
                        onClick={() => {
                          setFromDesign(true);
                          setSelectedPage("QR DESIGN");
                        }}
                        p={2}
                        style={{
                          marginTop: "-15px",
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryColor
                              : themeStyle.primaryColor,
                          color:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryButtonTextColor
                              : themeStyle.primaryButtonTextColor,
                          height: "25px",
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                            letterSpacing: "1.5px",
                          }}
                        >
                          QR DESIGN
                        </h3>
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        value="QR DESIGN"
                        onClick={() => {
                          setFromDesign(true);
                          setSelectedPage("QR DESIGN");
                        }}
                        p={2}
                        style={{
                          height: "25px",
                          marginTop: "-15px",
                          backgroundColor:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryCardColor
                              : themeStyle.primaryCardColor,
                          color:
                            state.auth.theme == "dark"
                              ? themeStyleDark.primaryButtonTextColor
                              : themeStyle.primaryButtonTextColor,
                        }}
                        borderRadius={6}
                      >
                        <h3
                          style={{
                            marginTop: "17px",
                            fontSize: "15px",
                            color: "#084594",
                          }}
                        >
                          QR DESIGN
                        </h3>
                      </Button>
                    )}
                  </Grid>
                </Grid>
              }
            />
            <div
              style={{
                padding: "100px 100px 90px 165px",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                minHieght: "100vh",
                width: "100vw",
              }}
            >
              {getContent()}
            </div>
          </Fragment>
        );
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor:
            state.auth.theme == "dark"
              ? themeStyleDark.backgroundColor
              : themeStyle.backgroundColor,
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Profiles;
