import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import { Doughnut } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Chart } from "chart.js/auto";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Line } from "react-chartjs-2";
import { refreshData } from "../actions/auth";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import PersonIcon from "@mui/icons-material/Person";
import { API_URL } from "../actions/auth";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { themeStyle, themeStyleDark } from "../components/Components";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Contacts(props) {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [contacts, setContacts] = useState([]);

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const location = useLocation();

  function getData() {
    var body = {
      uid: location.state.uid,
    };
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    axios.post(API_URL + "get_contacts", body, config).then((res) => {
      setContacts(res.data);
      setIsLoading(false);
    });
  }

  const downloadCSVFromJson = () => {
    var csv = "names,phones,emails\n";

    for (var i = 0; i < contacts.length; i++) {
      csv += contacts[i].name + ",";
      if (contacts[i].phones.length == 0) {
        csv += " ,";
      } else {
        for (var j = 0; j < contacts[i].phones.length; j++) {
          try {
            csv += contacts[i].phones[j].number + " ";
            if (j == contacts[i].phones.length - 1) {
              csv += ",";
            }
          } catch (err) {
            csv += ",";
          }
        }
      }
      if (contacts[i].emails.length == 0) {
        csv += " ,\n";
      } else {
        for (var k = 0; k < contacts[i].emails.length; k++) {
          try {
            csv += contacts[i].emails[j].address + " ";
            if (k == contacts[i].emails.length - 1) {
              csv += ",\n";
            }
          } catch (err) {
            csv += ",\n";
          }
        }
      }
    }

    var link = document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    );
    link.setAttribute("download", location.state.name);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  if (state.auth.refresh.includes("dashboard")) {
    getData();
    dispatch(refreshData("dashboard"));
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (isLoading) {
        return (
          <Fragment>
            <Sidebar />
            <div
              style={{
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                style={{
                  color:
                    state.auth.theme == "dark"
                      ? themeStyleDark.primaryColor
                      : themeStyle.primaryColor,
                }}
              />
            </div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Sidebar />
            <div
              style={{
                padding: "100px 100px 90px 165px",
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                minHeight: "120vh",
                width: "100vw",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryColor
                        : themeStyle.primaryColor,
                    color: "black",
                    fontSize: "20px",
                    marginBottom: "30px",
                  }}
                  onClick={() => {
                    downloadCSVFromJson();
                  }}
                >
                  DOWNLOAD CSV
                </Button>
              </div>
              {contacts.map((contact) => (
                <Accordion
                  style={{
                    backgroundColor:
                      state.auth.theme == "dark"
                        ? themeStyleDark.primaryCardColor
                        : themeStyle.primaryCardColor,
                    color:
                      state.auth.theme == "dark"
                        ? themeStyleDark.textColor
                        : themeStyle.textColor,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    {contact.name}
                  </AccordionSummary>
                  {contact.emails.length != 0 ? (
                    <AccordionDetails>
                      {contact.emails.map((email) => email.toString())}
                    </AccordionDetails>
                  ) : (
                    ""
                  )}
                  <AccordionDetails>
                    {contact.phones.map((phone) => phone.number)}
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </Fragment>
        );
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          backgroundColor:
            state.auth.theme == "dark"
              ? themeStyleDark.backgroundColor
              : themeStyle.backgroundColor,
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress
          style={{
            color:
              state.auth.theme == "dark"
                ? themeStyleDark.primaryColor
                : themeStyle.primaryColor,
          }}
        />
      </div>
    );
  }
}

export default Contacts;
